.Member {
  text-align: center;
  background-color: #fff;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.member__img {
  width: 380px;
  height: 260px;
  object-fit: cover;
}