body {
  padding: 0;
  margin: 0;
}

.card-list {
  display: flex;
  padding: 1rem;
  overflow-x: scroll;
}
::-webkit-scrollbar {
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #70bb77;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, .3);
}

.card {
  background: #fff;
  position: relative;
  flex-direction: column;
  height: 375px;
  width: 250px;
  min-width: 240px;
  padding: 2rem;
  margin: 0;
  display: flex;
  flex-direction: column;
  transition: .2s;
  scroll-snap-align: start;
  clear: both;

  border-radius: 59px !important;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: var(--smartid-color) !important;
}

.card:hover {
  transform: translateY(-0.5rem);
}

.card:focus-within~.card, .card:hover~.card {
  transform: translateX(80px);
}


.card:not(:first-child) {
  margin-left: -70px;
}

.card-header {
  margin-bottom: auto;
}

.card-header p {
  font-size: 14px;
  margin: 0 0 1rem;
  color: #7a7a8c;
}

.card-header h2 {
  font-size: 20px;
  text-decoration: none;
  color: #7a7a8c;
  border: 0;
  display: inline-block;
  cursor: pointer;
  text-align: left;
}

.card-header h2:hover {
  background: linear-gradient(90deg,#00bcd4,#00bcd4);
  text-shadow: none;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.card-author {
  margin: 3rem 0 0;
  display: grid;
  grid-template-columns: 75px 1fr;
  align-items: center;
  position: relative;
}

.author-avatar {
  grid-area: auto;
  align-self: start;
  position: relative;
  box-sizing: border-box;
}

.author-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  filter: grayscale(100%);
  display: block;
  overflow: hidden;
  margin: 16px 10px;
}

.author-name {
  grid-area: auto;
  box-sizing: border-box;
}

.author-name-prefix {
  font-style: normal;
  font-weight: 270;
  font-size: 21px;
  color: #7a7a8c;
  margin-bottom: 5px;
}

.tags {
  margin: 1rem 0 2rem;
  padding: .5rem 0 1rem;
  margin-bottom: 0;
}

.tags p {
  font-style: normal;
  font-weight: 700;
  font-size: 5px;
  color: #7a7a8c;
  text-transform: uppercase;
  border: 1px solid #70bb77;
  border-radius: 2rem;
  padding: .2rem .85rem .25rem;
  position: relative;
}

.tags p:hover {
  background: linear-gradient(90deg,#00bcd4,#00bcd4);
  text-shadow: none;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  border-color: #00bcd4;
}