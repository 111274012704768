.main {
  position: fixed;
  top: 12vh;
  width: 100%;
  transition-timing-function: ease-in;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  -webkit-box-align: center;
  align-items: center !important;
  margin-top: 30px;
}

.button {
  cursor: pointer;
  width: 300px;
  min-width: calc(85.33px);
  min-height: 53px;
  background-color: var(--smartid-background) !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: var(--smartid-color) !important;
  color: var(--smartid-color) !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  text-transform: inherit !important;
  border-radius: 9999px !important;
  margin-top: 20px !important;
  transition: transform 250ms !important;
}

.button:hover {
  transform: scale(1.03);
}

.button--submit {
  cursor: pointer;
  width: 300px;
  min-width: calc(85.33px);
  min-height: 53px;
  background-color: var(--smartid-color) !important;
  border: none !important;
  color: white !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  text-transform: inherit !important;
  border-radius: 9999px !important;
  margin-top: 20px !important;
  transition: transform 250ms !important;
}

.button--submit:hover {
  transform: scale(1.03);
}

.main__top {
  border-width: 1px !important;
  border-style: solid !important;
  border-color: var(--smartid-color) !important;
  width: 90%;
  /* display: inline-block; */
  /* overflow: hidden; */
  /* flex: 1 1 auto; */
  /* position: relative; */
  /* white-space: nowrap; */
  /* box-sizing: border-box; */
}
/* 
.main__button {
  cursor: pointer;
  width: 15vh;
  min-width: calc(85.33px);
  height: 37.5px;
  background-color: var(--smartid-background) !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: var(--smartid-color) !important;
  color: var(--smartid-color) !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  text-transform: inherit !important;
  border-radius: 9999px !important;
  transition: transform 250ms !important;
}
 */
