
.button--login {
  cursor: pointer;
  width: 300px;
  min-width: calc(85.33px);
  min-height: 53px;
  background-color: var(--smartid-background) !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: var(--smartid-color) !important;
  color: var(--smartid-color) !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  text-transform: inherit !important;
  border-radius: 9999px !important;
  margin-top: 20px !important;
  transition: transform 250ms !important;
}

.button--login:hover {
  transform: scale(1.03);
}

.button--modal {
  cursor: pointer;
  min-width: calc(85.33px);
  min-height: 20px;
  background-color: var(--smartid-background) !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: var(--smartid-color) !important;
  color: var(--smartid-color) !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  text-transform: inherit !important;
  border-radius: 9999px !important;
  transition: transform 250ms !important;
}

.button--modal:hover {
  transform: scale(1.06);
}
