body {
  --smartid-color: #4DAA56;
  --smartid-background: #fff;
}

.App {
  text-align: center;
  background-color: #fff;
  min-height: 90vh;
  min-width: 438px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-logo {
  width: 300px;
  height: 236px;
  pointer-events: none;
  margin-bottom: 30px;
}

.App-link {
  color: #61dafb;
}