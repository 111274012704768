.navi {
  position: fixed;
  top: 0;
  width: 100%;
  height: 75px;
  transition-timing-function: ease-in;
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.navi__logo {
  width: 60px;
  margin-top: 7.5px;
  margin-left: 8vh;
  margin-right: 8vh;
}

.navi__button {
  cursor: pointer;
  width: 15vh;
  min-width: calc(85.33px);
  height: 37.5px;
  background-color: var(--smartid-background) !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: var(--smartid-color) !important;
  color: var(--smartid-color) !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  text-transform: inherit !important;
  border-radius: 9999px !important;
  transition: transform 250ms !important;
}

.navi__button:hover {
  transform: scale(1.03);
}

.navi__left {
  width: 100%;
  height: 75px;
  margin-top: 50px;
  float: left;
}
.navi__right{
  width: 100%;
  height: 75px;
  margin-top: 50px;
  float: right;
}